<template>
  <a-card>
    <a-form-model
      ref="search_form"
      v-bind="layout"
      :model="search_form"
      class="search-form"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="主播昵称">
            <a-input
              v-model.trim="search_form.dealer_code"
              placeholder="请输入"
              style="width: 210px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="平台">
            <a-select
              v-model="search_form.platform_id"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              style="width: 210px"
            >
              <a-select-option
                v-for="item in platform_list"
                :key="item.id"
                :name="item.platform_name"
              >
                {{ item.platform_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="经销商名称">
            <a-input
              v-model.trim="search_form.dealer_name"
              placeholder="请输入"
              style="width: 210px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="监控状态">
            <a-select
              v-model="search_form.monitoring_status"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              style="width: 210px"
            >
              <a-select-option value="1"> 监控中 </a-select-option>
              <a-select-option value="2"> 已过期 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <header class="row-flex">
      <p>
        系统已监控主播{{
          author_count.total_monit_size
        }}位；当前用户监控主播总数{{ author_count.current_monit_size }}位
      </p>
      <div>
        <a-button type="primary" style="margin-right: 8px" @click="searchBtn"
          >搜索</a-button
        >
        <a-button style="margin-right: 8px" @click="resetBtn">重置</a-button>
        <a-button
          type="primary"
          icon="plus"
          @click="addAuthorRgt"
          :loading="addBtnLoading"
          >添加监控主播</a-button
        >
      </div>
    </header>
    <!-- 表格 -->
    <a-table
      :columns="columns"
      :data-source="tableList"
      :loading="tableLoading"
      :pagination="false"
      :rowKey="(record, index) => index"
    >
      <div slot="status_title">
        授权状态
        <a-tooltip placement="top">
          <template slot="title">
            <span>任意级别授权为绿色即可</span>
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
      </div>

      <div slot="time_title">
        时间信息
        <a-tooltip placement="top">
          <template slot="title">
            <span>视频监控起始时间之后发布的短视频才会被系统监控</span>
          </template>
          <a-icon type="exclamation-circle" />
        </a-tooltip>
      </div>

      <div slot="authorMessage" slot-scope="text, record">
        <div class="row-box anthor-box">
          <div><span>头</span><span>像：</span></div>
          <div>
            <div class="avatar-box">
              <img :src="record.avatar" class="avatar" />
            </div>
          </div>
        </div>
        <div class="row-box anthor-box">
          <div><span>昵</span><span>称：</span></div>
          <div>{{ record.nickname }}</div>
        </div>
        <div class="row-box anthor-box">
          <div><span>平</span><span>台：</span></div>
          <div>
            {{
              record.platform == 1
                ? "抖音"
                : record.platform == 2
                ? "快手"
                : "懂车帝"
            }}
          </div>
        </div>
        <div class="row-box anthor-box">
          <div><span>经</span><span>销</span><span>商：</span></div>
          <div>{{ record.dealer_name || '——'}}</div>
        </div>
      </div>
      <div slot="publish_time" slot-scope="text, record" class="time_message">
        <span>创建时间：</span>
        <span class="time">{{ record.ctime }}</span>
        <span>视频监控起始时间：</span>
        <span class="time">{{ record.start_time }}</span>
        <span v-if="record.end_time">过期时间：</span>
        <span class="time" v-if="record.end_time">{{ record.end_time }}</span>
      </div>
      <div slot="validity" slot-scope="text">
        <span v-if="text > 0">{{ text }}天</span>
        <span v-else style="color: red">过期{{ text }}天</span>
      </div>
      <div slot="accredit_status" slot-scope="text, record">
        <a-tag
          :color="judgeColor(record.serivce_auth_status)"
          >p1</a-tag
        >
        <a-tag
          :color="judgeColor(record.dyopen_auth_status)"
          >p2</a-tag
        >
      </div>
      <div slot="monit_status" slot-scope="text">
        <!-- 1:监控中，2:已过期 -->
        <a-tag v-if="text == 1" color="green">监控中</a-tag>
        <a-tag v-else color="red">已过期</a-tag>
      </div>
      <div
        slot="ossUrl"
        slot-scope="text, record"
        style="display: flex; align-items: center"
      >
        <a-button type="link" @click="openRuleList(record.rule_group_id)"
          >查看规则</a-button
        >
        <a-divider type="vertical" v-if="record.monit_status == 2" />
        <a-button
          type="link"
          @click="contractTime(record)"
          :loading="btnLoading && record.id == activeId"
          v-if="record.monit_status == 2"
          >续期</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          title="你确定要移除该主播？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteBindDealer(record.id)"
        >
          <a-button type="link" style="color: red" :loading="deleteLoading"
            >退出监控</a-button
          >
        </a-popconfirm>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
    <rule-list
      :visible="visible_rule"
      :ruleData="rule_table"
      @closeModel="closeRuleModel"
    />
    <!-- 企微码 -->
    <a-modal v-model="codeModel" :footer="null" >
      <div slot="title">
        绑定企微<span class="code_title">当前帐号没有绑定企微，请扫码绑定</span>
      </div>
      <div class="wx_reg">
        <div id="wx_reg"></div>
      </div>
    </a-modal>
  </a-card>
</template>
<script>
import RuleList from "./components/RuleList";
import buryEvent from '@/utils/buryEvent.js'
const columns = [
  {
    title: "主播信息",
    dataIndex: "author_id",
    scopedSlots: { customRender: "authorMessage" },
    width: 270,
  },
  {
    title: "规则名称",
    dataIndex: "rule_name",
  },
  {
    slots: { title: "time_title" },
    dataIndex: "publish_time",
    scopedSlots: { customRender: "publish_time" },
  },
  {
    title: "有效期",
    dataIndex: "validity",
    scopedSlots: { customRender: "validity" },
  },
  {
    slots: { title: "status_title" },
    dataIndex: "publish_status",
    scopedSlots: { customRender: "accredit_status" },
  },
  {
    title: "状态",
    dataIndex: "monit_status",
    scopedSlots: { customRender: "monit_status" },
  },
  {
    title: "操作",
    dataIndex: "ossUrl",
    scopedSlots: { customRender: "ossUrl" },
    width: 70,
  },
];
export default {
  components: {
    RuleList,
  },
  data() {
    return {
      rule_name: "",
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      tableList: [],
      tableLoading: false,
      columns,
      platform_list: [
        {
          platform_name: "抖音",
          id: "1",
        },
        {
          platform_name: "快手",
          id: "2",
        },
        {
          platform_name: "懂车帝",
          id: "3",
        },
      ],
      search_form: {
        dealer_code: "",
        platform_id: [],
        dealer_name: "",
        monitoring_status: [],
      },
      visible_rule: false,
      btnLoading: false,
      deleteLoading: false,
      rule_table: [],
      codeModel: false,
      addBtnLoading: false,
      activeId: "",
      author_count: {},
    };
  },
  created() {
    if (this.$route.query.ticket) {
      let params = {
        qy_wechat: this.$route.query.ticket,
      };
      this.$nextTick(() => {
        setTimeout(() => {
          // if (this.userInfoData && !this.userInfoData.oauth_user_id) {
          this.qyWechat(params);
          // }
        }, 1000);
      });
    } else {
      this.$router.push({ query: {} });
    }
  },
  mounted() {
    this.getAuthorList(1, 10);
    this.$api.core.PutAssistant.getMonitAuthor().then((res) => {
      if (res.code == 0) {
        this.author_count = res.data;
      }
    });
  },
  methods: {
    // 企微绑定
    qyWechat(params) {
      // this.isSpin = true
      this.$api.core.PutAssistant.qyWechat(params)
        .then((res) => {
          if (res.code === 200) {
            // this.getUserInfoData();
            this.$api.AppService.getUserInfo().then((res) => {
              if (res.code == 200) {
                this.avatar_url = res.data.avatar_url;
                sessionStorage.setItem("avatar_url", res.data.avatar_url);
              }
            });
            this.$router.push({ query: {} });
          } else {
            this.$message.error(res.message);
            this.$router.push({ query: {} });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    searchBtn() {
      this.getAuthorList(1, 10);
    },
    // 颜色定义
    judgeColor(value) {
      let color = "";
      switch (value) {
        case "1":
          color = "green";
          break;
        case "2":
          color = "red";
          break;
        case null:
          color = "red";
          break;
        default:
          color = "";
          break;
      }
      return color;
    },
    resetBtn() {
      this.search_form = {
        dealer_code: "",
        platform_id: [],
        dealer_name: "",
        monitoring_status: "",
      };
      this.getAuthorList(1, 10);
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getAuthorList(current, pageSize);
    },
    // 获取主播列表
    getAuthorList(current, page_num) {
      const { dealer_code, platform_id, dealer_name, monitoring_status } =
        this.search_form;
      let params = {
        nickname: dealer_code,
        platform: platform_id,
        dealer_name,
        monit_status: monitoring_status,
        page_num: current,
        page_size: page_num,
      };
      this.tableLoading = true;
      this.$api.core.PutAssistant.authorList(params)
        .then((res) => {
          if (res.code == 0) {
            this.tableList = res.data.list;
            const { current, page_size, total } = res.data.pagination;
            this.pagination = {
              current,
              page_size: page_size,
              total: total,
            };
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 添加主播监控
    addAuthorRgt() {
      this.addBtnLoading = true;
      this.$api.core.PutAssistant.judgeWechat()
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.oauth_user_id) {
              this.$router.push({
                name: "regulation:add",
              });
              buryEvent("monitor", "jump-to-add")
            } else {
              this.showCodeModel();
            }
          }
        })
        .finally(() => {
          this.addBtnLoading = false;
        });
    },
    // 退出监控
    deleteBindDealer(id) {
      this.$api.core.PutAssistant.cancelMonitor(id).then((res) => {
        if (res.code == 0) {
          this.$message.success("退出监控成功！");
          this.getAuthorList(this.pagination.current, 10);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 续期
    contractTime(value) {
      this.btnLoading = true;
      this.activeId = value.id;
      this.$api.core.PutAssistant.continueMonitor(value.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("续期成功！");
            this.getAuthorList(this.pagination.current, 10);
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    showCodeModel() {
      let URL = window.location.href;
      let params = {
        redirect_uri: URL,
      };
      this.$api.core.PutAssistant.wchatCode(params).then((res) => {
        const { agentid, appid, redirect_uri, stat } = res.data;
        window.WwLogin({
          id: "wx_reg",
          appid, //appid
          agentid, //agentid
          redirect_uri, //回调地址，注意回调地址需要进行urlencode
          state: stat, //用于保持请求和回调的状态，授权请求后原样带回给企业。该参数可用于防止csrf攻击,参数非必填
          // "href" : "XXXX",//自定义样式链接，企业可根据实际需求覆盖默认样式，参数非必填
        });
      });
      this.codeModel = true;
    },
    closeRuleModel() {
      this.visible_rule = false;
    },
    openRuleList(id) {
      this.visible_rule = true;
      this.$api.core.PutAssistant.getRuleList(id).then((res) => {
        if (res.code == 0) {
          this.rule_table = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.row-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.anthor {
  display: flex;
  margin: 0;
  & + & {
    margin-top: 5px;
  }
  span:last-child {
    flex: 1;
  }
}
.anthor-box {
  & > div:first-child {
    width: 75px;
  }
}
.anthor-box {
  .long-loyout {
    width: 75px !important;
    // text-align: right;
  }
}

.avatar-box {
  width: 68px;
  height: 68px;
  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
}

.row-box {
  display: flex;
  margin-bottom: 5px;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;
    .title {
      display: flex;
      justify-content: space-between;
    }
  }
  & > div:last-child {
    flex: 1;
    min-width: 50px;
  }
}
.time_message {
  display: flex;
  flex-flow: column;
  .time {
    color: #888;
  }
}
.wx_reg {
  display: flex;
  justify-content: center;
}
.code_title {
  color: #bbb;
  display: inline-block;
  margin-left: 12px;
  font-size: 12px;
}
</style>