<template>
  <a-modal
    v-model="visible"
    title="规则列表"
    :footer="null"
    @cancel="closeModel"
  >
    <a-table
      :columns="columns"
      :data-source="ruleData"
      bordered
      :pagination="false"
      :scroll="{ y: 340 }"
    >
      <div slot="name" slot-scope="text">{{judgeType(text)}}</div>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: "触发时间",
    dataIndex: "rule_type",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "规则说明",
    dataIndex: "rule_desc",
  },
];

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ruleData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      columns,
    };
  },
  methods: {
    closeModel() {
      this.$emit("closeModel");
    },
    judgeType(value) {
      let desc = "";
      switch (value) {
        case 101:
          desc = "主播发布新视频";
          break;
        case 102:
          desc = "主播监控过期";
          break;
        case 103:
          desc = "主播移出监控列表";
          break;
        case 201:
          desc = "短视频发布后2小时内";
          break;
        case 202:
          desc = "短视频发布后2小时内";
          break;
        case 203:
          desc = "短视频发布后6小时内";
          break;
        case 204:
          desc = "短视频发布后24小时内";
          break;
        case 205:
          desc = "短视频发布后48小时内";
          break;
        case 206:
          desc = "任意时刻";
          break;
        default:
          break;
      }
      return desc
    },
  },
};
</script>